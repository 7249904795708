import './Contact.css'
import ReactDOM from 'react-dom'

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, Navigate } from 'react-router-dom'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, child, get, update } from 'firebase/database';
import emailjs from '@emailjs/browser';

import { doc } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
};
function t(){
  emailjs.init({
    publicKey: "dKJx1zxJp-wOkmVeg",
  })}
  t()
  
var infogreat = []
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function Contact() {
  useEffect(() => {
    document.title = 'Rebecq Vivant | Contact';
  }, []);
  window.scrollTo(0, 0)
    window.addEventListener('scroll',()=>{
        console.log(window.scrollY);
        if (window.scrollY>=4 && window.innerWidth >825) {
             document.getElementById('navbar').style.backgroundColor = 'white'
                document.getElementById('navbar').style.boxShadow = '1px 1px 30px black'
                document.getElementById('link').style.color = 'black'
                document.getElementById('link1').style.color = 'black'
                document.getElementById('link2').style.color = 'black'
                document.getElementById('link3').style.color = 'black'
                document.getElementById('link4').style.color = 'black'
                document.getElementById('link5').style.color = 'black'
        }
        else if (window.scrollY>=100 && window.innerWidth <=825) {
             document.getElementById('hamburger').style.backgroundColor = "black"
        }
        else if(window.scrollY<4){
          document.getElementById('hamburger').style.backgroundColor = "transparent"
            document.getElementById('navbar').style.backgroundColor = 'transparent'
                document.getElementById('navbar').style.boxShadow = ''
                document.getElementById('link').style.color = 'white'
                document.getElementById('link1').style.color = 'white'
                document.getElementById('link2').style.color = 'white'
                document.getElementById('link3').style.color = 'white'
                document.getElementById('link4').style.color = 'white'
                document.getElementById('link5').style.color = 'white'
        }
    })
    return(
        <div className='main_contact' >
            <div className='degra3' ></div>
            <div className='hamburger' id='hamburger' onClick={()=>{
               const hamb1 = document.getElementById('hamb1')
               const hamb2 = document.getElementById('hamb2')

        if (document.getElementById('header').style.display == 'block') {
             hamb1.style.transform = 'rotate(0deg)'
            hamb2.style.transform = 'rotate(0deg)'

            document.getElementById('hamb_hide').style.backgroundColor ='white'
            document.getElementById('hamb_del2').style.backgroundColor ='white'
            document.getElementById('hamb_del').style.backgroundColor ='white'
          document.getElementById('header').style.animationName = 'disapear'
          setTimeout(() => {
            document.getElementById('header').style.animationName = 'none'


            document.getElementById('header').style.display = 'none'
          }, 1000);
          }else{
             document.getElementById('hamb_del2').style.backgroundColor ='transparent'
            document.getElementById('hamb_del').style.backgroundColor ='transparent'
            document.getElementById('hamb_hide').style.backgroundColor ='transparent'
            hamb1.style.transform = 'rotate(45deg)'
            hamb2.style.transform = 'rotate(-45deg)'
      document.getElementById('header').style.display = 'block'
        }
      }}>
          <div style={{transition:'.3s all ease-in-out', backgroundColor:'white'}} id="hamb_del" ></div>
          <div id="hamb_hide" style={{marginLeft:'.1em' ,position:'relative', transition:'.3s all ease-in-out'}} >
            <div id="hamb1" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
            <div id="hamb2" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
          </div>
          <div style={{transition:'.3s all ease-in-out', backgroundColor:'white'}} id="hamb_del2" ></div>
      </div>
              <div className='fixed' >
              <header id='header'>
              <nav id='navbar' className='navbar'>
                <img className='logo' src='/newlogo.JPG' ></img>
                <ul className='nav-links'>
                <li  > <Link className='linkreact' id='link' to='/' > Accueil</Link></li>
                  <li  > <Link  className='linkreact' id='link1' to='/programme' >Programme</Link></li>
                  <li ><Link  className='linkreact' id='link2' to='/actualité' >Actualité</Link></li>
                  <li   ><Link  className='linkreact' id='link3' to='/agenda' >Agenda</Link></li>
                  <li  > <Link  className='linkreact' id='link4' to='/àpropos' >À propos</Link></li>
                  <li ><Link  className='linkreact' id='link5' to='/contact' >Contact</Link></li>
                </ul>
              </nav>
              </header>
          
              </div>
              <h1 className='h1_contact' >Contactez-Nous</h1>
              <div className='wrap_contact' >

              <div className='wrap_formulaire' >
                <form id='formf'>

                <input placeholder='Prénom' id='prenom' name='from_name' ></input>

                <input placeholder='Nom' id='nom' name='last_name'></input>
                <input placeholder='E-mail'id='mail' name='mail' ></input> <br></br>
                <textarea placeholder='Votre message' id='message' name='message' ></textarea><br></br>
                <input className='checkbox' id='checkbox' type='checkbox' ></input> <label>J'accepte la <span className='conditions'  style={{cursor:'pointer'}} onClick={()=>{
                document.getElementById('conditions_div').style.display = 'block'
                document.getElementById('rem_condi').style.display = 'block'
                }} >Politique de confidentialité</span></label>
                <p className='cochez-p' id='cochez-p' >Acceptez la Politique de confidentialité</p>
                <p className='validation' id='validation' >C'est parti!</p>
                </form>
                <br></br>
                <button className='btn_contact' id='send-btn' onClick={()=>{
                 
                    console.log('click');
                    const db = getDatabase();
                    const prenom = document.getElementById('prenom')
                    const nom = document.getElementById('nom')
                    const message = document.getElementById('message')
                    const mail = document.getElementById('mail')
                    prenom.style.border = 'none'
                    nom.style.border = 'none'
                    mail.style.border = 'none'
                    message.style.border = 'none'
                     document.getElementById('cochez-p').style.display = 'none'
                    
                    console.log(mail.value.includes('L'));
                    if (prenom.value != '' && nom.value != '' && mail.value.includes('@') && message.value != '' && document.getElementById('checkbox').checked == true) {

                      emailjs.sendForm('service_rv', 'template_vduio46', '#formf').then(
                        (response) => {
                          console.log('SUCCESS!', response.status, response.text);
                          prenom.value = ''
                          nom.value = ''
                          mail.value = ''
                          message.value = ''
                          document.getElementById('checkbox').checked = false
                          document.getElementById('validation').style.display = 'block'
                          document.getElementById('checkbox').checked = false
                          
                        },
                        (error) => {
                          console.log('FAILED...', error);
                        },
                      );
                    }
                    else if(prenom.value == ''){
                        prenom.style.border = '2px solid red'
                    }
                    else if(nom.value == ''){
                        nom.style.border = '2px solid red'
                    }
                    else if(mail.value.includes('@') == false){
                        mail.style.border = '2px solid red'
                    }
                    else if(message.value == ''){
                        message.style.border = '2px solid red'
                    }
                    else if(document.getElementById('checkbox').checked == false){
                     
                      
                      document.getElementById('cochez-p').style.display = 'block'
                    }
                }} >Envoyer</button>
              </div>
              <div className='info_contact' >
                <h5 className='info_contact_h5' > <img src='/envelope.svg' ></img> Email</h5>
                <p className='info_contact_p' style={{cursor:'pointer'}} onClick={()=>{
                     window.open(
                      "mailto: info@rebecqvivant.be")
                }} >info@rebecqvivant.be</p>

              </div>
              </div>
              <div className='rem_cond' id='rem_condi' onClick={()=>{
                document.getElementById('conditions_div').style.display = 'none'
                document.getElementById('rem_condi').style.display = 'none'
              }} ></div>
              <div className='conditions_div' id='conditions_div' onClick={()=>{
                document.getElementById('conditions_div').style.display = 'none'
                document.getElementById('rem_condi').style.display = 'none'
              }} >
                <p>
               <span className='titrepoli' >Politique de confidentialité</span><br></br>
Le présent site web est géré par les membres de l’association de fait Rebecq Vivant.<br></br>
Nous accordons une grande importance à la protection de votre vie privée et de vos données personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons vos données personnelles lorsque vous utilisez notre site et notre formulaire de contact. Elle inclut en particulier :<br></br>
- Les données personnelles que nous recueillons ;<br></br>
- L’utilisation des données recueillies ;<br></br>
- Qui a accès aux données recueillies ;<br></br>
- Les droits des utilisateurs du site ;<br></br>
- La politique de cookies du site.<br></br>
Cette politique de confidentialité fonctionne parallèlement aux éventuelles conditions générales d’utilisation de notre site.<br></br>

**Lois applicables**<br></br>
Cette politique de confidentialité est conforme au Règlement général sur la protection des données (RGPD).<br></br>
Le traitement n'est licite que si, et dans la mesure où, au moins une des conditions suivantes est remplie :<br></br>
a) la personne concernée a consenti au traitement de ses données à caractère personnel pour une ou plusieurs finalités spécifiques ;<br></br>
b) le traitement est nécessaire à l'exécution d'un contrat auquel la personne concernée est partie ou à l'exécution de mesures précontractuelles prises à la demande de celle-ci ;<br></br>
c) le traitement est nécessaire au respect d'une obligation légale à laquelle le responsable du traitement est soumis ;<br></br>
d) le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique ;<br></br>
e) le traitement est nécessaire à l'exécution d'une mission d'intérêt public ou relevant de l'exercice de l'autorité publique dont est investi le responsable du traitement ;<br></br>
f) le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le responsable du traitement ou par un tiers, à moins que ne prévalent les intérêts ou les libertés et droits fondamentaux de la personne concernée qui exigent une protection des données à caractère personnel, notamment lorsque la personne concernée est un enfant.<br></br>

**Consentement**<br></br>
Les utilisateurs conviennent qu’en utilisant notre site, ils consentent à :<br></br>
- Les conditions énoncées dans la présente politique de confidentialité et<br></br>
- La collecte, l’utilisation et la conservation des données, telles qu’énumérées dans la présente politique.<br></br>

**Collecte de données personnelles**<br></br>
Nous collectons les données personnelles que vous nous fournissez lorsque vous remplissez un formulaire de contact ou toute autre fonctionnalité du site (ex : don,…).<br></br>
Les données personnelles que nous collectons peuvent inclure votre nom, votre prénom, votre adresse e-mail, votre domicile, votre numéro de téléphone, vos informations de paiement.<br></br>
Les données que nous collectons sont celles que vous remplissez vous-même sur le site.<br></br>
Veuillez noter que nous ne collectons que les données qui nous aident à atteindre l’objectif énoncé dans cette politique de confidentialité. Nous ne recueillerons pas de données supplémentaires sans vous en informer au préalable.<br></br>

**Utilisation des données personnelles**<br></br>
Les données personnelles recueillies sur notre site seront utilisées uniquement aux fins précisées dans la présente politique ou indiquées sur les pages pertinentes de notre site.<br></br>
Les données que nous collectons sont utilisées aux fins suivantes :<br></br>
- répondre à vos demandes de renseignements ;<br></br>
- répondre aux demandes qui nous sont faites sur base de la réglementation ;<br></br>
- vous envoyer des informations si vous en avez demandé ou si vous avez demandé à vous inscrire à notre newsletter.<br></br>

**Protection des données personnelles – avec qui nous partageons les données personnelles**<br></br>
Nous prenons des mesures techniques et organisationnelles appropriées pour protéger vos données personnelles contre la perte, la destruction, la divulgation ou l'accès non autorisé.<br></br>
Nous limitons l'accès à vos données personnelles aux membres de notre organisation qui ont besoin d'y accéder pour réaliser les objectifs énoncés dans la présente politique.<br></br>
Nous pouvons toutefois partager vos données personnelles avec des partenaires qui nous aident à fournir nos services, tels que des prestataires de paiement. Les tiers ne seront pas en mesure d’accéder aux données des utilisateurs au-delà de ce qui est raisonnablement nécessaire pour atteindre l’objectif donné.<br></br>
Nous nous engageons à ne pas vendre ou partager vos données avec des tiers sauf dans les cas suivants :<br></br>
- Si la loi l’exige ;<br></br>
- Si elle est requise pour toute procédure judiciaire ;<br></br>
- Pour prouver ou protéger nos droits légaux.<br></br>
Si vous suivez des hyperliens de notre site vers un autre site, veuillez noter que nous ne sommes pas responsables et n’avons pas de contrôle sur leurs politiques et pratiques de confidentialité.<br></br>
Nous prenons toutes les précautions raisonnables pour assurer que nos données d’utilisateur sont sécurisées et que nos utilisateurs sont protégés, mais nous ne pouvons garantir de tout préjudice : l’Internet dans sa totalité peut parfois être peu sécurisé et nous ne pouvons garantir la sécurité des données des utilisateurs au-delà du raisonnable.<br></br>

**Droits des personnes concernées**<br></br>
En vertu du RGPD, les utilisateurs ont les droits suivants en tant que personnes concernées :<br></br>
- Droit d’accès ;<br></br>
- Droit de rectification ;<br></br>
- Droit à l’effacement ;<br></br>
- Droit de restreindre le traitement ;<br></br>
- Droit à la portabilité des données ;<br></br>
- Droit d’objection.<br></br>
Vous trouverez de plus amples informations sur ces droits au chapitre 3 (articles 12 à 23) du RGPD.<br></br>
Sur cette base, vous avez le droit d'accéder à vos données personnelles, de les rectifier, de les effacer ou de limiter leur traitement. Vous pouvez également vous opposer au traitement de vos données personnelles ou demander leur transfert à un autre responsable du traitement. Vous pouvez exercer ces droits (demander de supprimer ou modifier vos données notamment) en nous contactant à l'adresse électronique suivante : rebecqvivant@gmail.com.<br></br>
Le RGPD précise que les personnes de moins de 15 ans sont considérées comme des mineurs aux fins de la collecte de données. Les mineurs doivent avoir le consentement d’un représentant légal pour que leurs données soient recueillies, traitées et utilisées.<br></br>

**Durée de conservation des données personnelles**<br></br>
Nous ne conservons vos données personnelles que pendant la durée nécessaire pour atteindre les fins pour lesquelles elles sont recueillies, notamment pour fournir nos services ou pour nous conformer à nos obligations légales, et ce pour un maximum de 10 ans. Nous pouvons toutefois conserver vos données personnelles plus longtemps si cela est nécessaire pour nous protéger contre des réclamations légales.<br></br>

**Politique sur les cookies**<br></br>
Un cookie est un petit fichier, stocké sur le disque dur d’un utilisateur par le site web. Son but est de recueillir des données relatives aux habitudes de navigation de l’utilisateur. Nous utilisons les types de cookies suivants sur notre site : rebecqvivant.be/rebecqvivant.com.<br></br>
Vous pouvez choisir d’être averti chaque fois qu’un cookie est transmis. Vous pouvez également choisir de désactiver les cookies entièrement dans votre navigateur Internet, mais cela peut diminuer la qualité de votre expérience d’utilisation.<br></br>

**Modifications de la politique de confidentialité**<br></br>
Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment afin de maintenir la conformité avec la loi et tenir compte de tout changement dans notre processus de collecte des données. Nous recommandons à nos utilisateurs de vérifier notre politique de temps à autre pour s’assurer qu’ils soient informés de toute mise à jour. Les modifications apportées à cette politique de confidentialité entreront en vigueur immédiatement après leur publication sur notre site.<br></br>
Si vous avez des questions ou des préoccupations concernant notre politique de confidentialité, n'hésitez pas à nous contacter à l'adresse électronique suivante : rebecqvivant@gmail.com.<br></br>
Date d’entrée en vigueur : 22 juillet 2024<br></br>
Mise à jour : 26 septembre 2024<br></br>

                </p>
              </div>
              <footer>
                <div className='socials_footer' >
                <img  onClick={()=>{
                    window.open('https://www.instagram.com/rebecqvivant2024/', '_blank')
                  }} src='/footer/instagram.png' ></img>
                  <img onClick={()=>{
                     window.open(
                      "mailto: info@rebecqvivant.be")
                  }} src='/footer/email.png' ></img>
                  <img onClick={()=>{
                    window.open('https://www.facebook.com/rebecqvivant?locale=fr_FR', '_blank')
                  }} src='/footer/facebook.png' ></img>

                </div>
                <ul className='nav_footer' >
                <li  > <Link className='linkreact' id='link' to='/' > Accueil</Link></li>
                  <li  > <Link  className='linkreact' id='link1' to='/programme' >Programme</Link></li>
                  <li ><Link  className='linkreact' id='link2' to='/actualité' >Actualité</Link></li>
                  <li   ><Link  className='linkreact' id='link3' to='/agenda' >Agenda</Link></li>
                  <li  > <Link  className='linkreact' id='link4' to='/àpropos' >A propos</Link></li>
                  <li ><Link  className='linkreact' id='link5' to='/contact' >Contact</Link></li>
                </ul>


              </footer>
              <div className='pub_Neodia_Web' ><h6>Ces images sont soumises au droit d'auteur, veuillez nous contacter à <span onClick={()=>{
                window.open('mailto:info@rebecqvivant.be')
              }} >info@rebecqvivant.be</span>.</h6></div>
             <div className='pub_Neodia_Web' ><h6>Ce site a été créé par un jeune talent rebecquois.</h6></div>
        </div>
    )

}
export default Contact;