import './Propos.css'
import ReactDOM from 'react-dom'

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, Navigate } from 'react-router-dom'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, child, get } from 'firebase/database';
import { doc } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
};
var infogreat = []
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const dbRef = ref(getDatabase());
var listphoto = []
function Propos() {
  useEffect(() => {
    document.title = 'Rebecq Vivant | A propos';
  }, []);
  window.scrollTo(0, 0)
  get(child(dbRef, 'Membres')).then((snapshot) => {
  const fetchmembre = async ()=>{
    const meme = snapshot.val()
    const arraysnap = Object.keys(meme)
      
console.log(arraysnap.length)
      for (let i = 0; i <= arraysnap.length-1; i++) {
        console.log(i);
        
        await get(child(dbRef, 'Membres/'+arraysnap[i].toString()+'/Name')).then((snapshotname) => {
         get(child(dbRef, 'Membres/'+arraysnap[i].toString()+'/Image')).then((snapshotimage) => {

           get(child(dbRef, 'Membres/'+arraysnap[i].toString()+'/Post')).then((snapshotposte) => {
            console.log(snapshotimage.val()+'+'+snapshotname.val());
            if (snapshotposte.val() == 'cpas') {  
              createMembreCPASCard(snapshotimage.val(), '', '')
            }
            else if (snapshotposte.val()== 'comu') {
              createMembreConCard(snapshotimage.val(),'',"")
              
            }
            else{

              createMembreCard(snapshotimage.val(), snapshotname.val(), snapshotposte.val())
            }
          })
        })

        })
      }

    }
    fetchmembre()
  })
    get(child(dbRef, 'Galerie')).then((snapshot) => {
    console.log(snapshot.size);

    if (snapshot.size/4-Math.floor(snapshot.size/4)==0) {
      const fetchDatal = async () => {
        for (let i = 0; i < snapshot.size; i++) {

         await get(child(dbRef, 'Galerie/'+(i+1).toString())).then((snapshot1) => {
            console.log( i+1+':'+snapshot1.val());
            listphoto.push({
              img: snapshot1.val(),

          });
          })

        }
        console.log(listphoto);

         for (let i = 0; i < Math.floor(snapshot.size/4); i++) {


            createBigCardPhoto(listphoto, i)


        }
      }
      fetchDatal()


    }
    else if(snapshot.size/4-Math.floor(snapshot.size/4)==.25){

      const fetchDatal = async () => {
        for (let i = 0; i < snapshot.size; i++) {

         await get(child(dbRef, 'Galerie/'+(i+1).toString())).then((snapshot1) => {
            console.log( i+1+':'+snapshot1.val());
            listphoto.push({
              img: snapshot1.val(),

          });
          })

        }
        console.log(listphoto);

         for (let i = 0; i < Math.floor(snapshot.size/4); i++) {


            createBigCardPhoto(listphoto, i)


        }
      }
      fetchDatal()

      setTimeout(() => {

        get(child(dbRef, 'Galerie/'+snapshot.size)).then((snapshot) => {

          console.log(snapshot.val());
          createOneCardPhoto(snapshot.val())
        })
      }, 500);



    }
    else if(snapshot.size/4-Math.floor(snapshot.size/4)==.5){
      const fetchDatal = async () => {
        for (let i = 0; i < snapshot.size; i++) {

         await get(child(dbRef, 'Galerie/'+(i+1).toString())).then((snapshot1) => {
            console.log( i+1+':'+snapshot1.val());
            listphoto.push({
              img: snapshot1.val(),

          });
          })

        }
        console.log(listphoto);

         for (let i = 0; i < Math.floor(snapshot.size/4); i++) {


            createBigCardPhoto(listphoto, i)


        }
      }
      fetchDatal()
      setTimeout(() => {
        const size = snapshot.size
        const size2 = size-1
        console.log(size);
        get(child(dbRef, 'Galerie/'+size)).then((snapshot) => {

          get(child(dbRef, 'Galerie/'+size2)).then((snapshot1) => {
            createTwoCardPhoto(snapshot.val(), snapshot1.val())
          })

        })
      }, 500);
    }
    else if(snapshot.size/4-Math.floor(snapshot.size/4)==.75){
      const fetchDatal = async () => {
        for (let i = 0; i < snapshot.size; i++) {

         await get(child(dbRef, 'Galerie/'+(i+1).toString())).then((snapshot1) => {
            console.log( i+1+':'+snapshot1.val());
            listphoto.push({
              img: snapshot1.val(),

          });
          })

        }
        console.log(listphoto);

         for (let i = 0; i < Math.floor(snapshot.size/4); i++) {


            createBigCardPhoto(listphoto, i)


        }
      }
      fetchDatal()
      setTimeout(() => {
        const size = snapshot.size
        const size2 = snapshot.size
        const size3 = snapshot.size
        get(child(dbRef, 'Galerie/'+snapshot.size)).then((snapshot) => {
          get(child(dbRef, 'Galerie/'+snapshot.size-1)).then((snapshot1) => {
          get(child(dbRef, 'Galerie/'+snapshot.size-1)).then((snapshot2) => {

            createThreeCardPhoto(snapshot.val(), snapshot1.val(), snapshot2.val())
          })


          })



        })
      }, 500);

    }

  })

  window.addEventListener('scroll',()=>{
    console.log(window.scrollY);
    if (window.scrollY>=4 && window.innerWidth >825) {
         document.getElementById('navbar').style.backgroundColor = 'white'
            document.getElementById('navbar').style.boxShadow = '1px 1px 30px black'
            document.getElementById('link').style.color = 'black'
            document.getElementById('link1').style.color = 'black'
            document.getElementById('link2').style.color = 'black'
            document.getElementById('link3').style.color = 'black'
            document.getElementById('link4').style.color = 'black'
            document.getElementById('link5').style.color = 'black'
    }
    else if (window.scrollY>=100 && window.innerWidth <=825) {
         document.getElementById('hamburger').style.backgroundColor = "black"
    }
    else if(window.scrollY<4){
      document.getElementById('hamburger').style.backgroundColor = "transparent"
        document.getElementById('navbar').style.backgroundColor = 'transparent'
            document.getElementById('navbar').style.boxShadow = ''
            document.getElementById('link').style.color = 'white'
            document.getElementById('link1').style.color = 'white'
            document.getElementById('link2').style.color = 'white'
            document.getElementById('link3').style.color = 'white'
            document.getElementById('link4').style.color = 'white'
            document.getElementById('link5').style.color = 'white'
    }
})
    return(

       <div className='main_propos' >
         <div className='hamburger' id='hamburger' onClick={()=>{
               const hamb1 = document.getElementById('hamb1')
               const hamb2 = document.getElementById('hamb2')

        if (document.getElementById('header').style.display == 'block') {
             hamb1.style.transform = 'rotate(0deg)'
            hamb2.style.transform = 'rotate(0deg)'

            document.getElementById('hamb_hide').style.backgroundColor ='white'
            document.getElementById('hamb_del2').style.backgroundColor ='white'
            document.getElementById('hamb_del').style.backgroundColor ='white'
          document.getElementById('header').style.animationName = 'disapear'
          setTimeout(() => {
            document.getElementById('header').style.animationName = 'none'


            document.getElementById('header').style.display = 'none'
          }, 1000);
          }else{
             document.getElementById('hamb_del2').style.backgroundColor ='transparent'
            document.getElementById('hamb_del').style.backgroundColor ='transparent'
            document.getElementById('hamb_hide').style.backgroundColor ='transparent'
            hamb1.style.transform = 'rotate(45deg)'
            hamb2.style.transform = 'rotate(-45deg)'
      document.getElementById('header').style.display = 'block'
        }
      }}>
          <div style={{transition:'.3s all ease-in-out', backgroundColor:'white'}} id="hamb_del" ></div>
          <div id="hamb_hide" style={{marginLeft:'.1em' ,position:'relative', transition:'.3s all ease-in-out'}} >
            <div id="hamb1" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
            <div id="hamb2" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
          </div>
          <div style={{transition:'.3s all ease-in-out', backgroundColor:'white'}} id="hamb_del2" ></div>
      </div>
              <div className='fixed' >
              <header id='header'>
              <nav id='navbar' className='navbar'>
                <img className='logo' src='/newlogo.JPG' ></img>
                <ul className='nav-links'>
                <li  > <Link className='linkreact' id='link' to='/' > Accueil</Link></li>
                  <li  > <Link  className='linkreact' id='link1' to='/programme' >Programme</Link></li>
                  <li ><Link  className='linkreact' id='link2' to='/actualité' >Actualité</Link></li>
                  <li   ><Link  className='linkreact' id='link3' to='/agenda' >Agenda</Link></li>
                  <li  > <Link  className='linkreact' id='link4' to='/àpropos' >À propos</Link></li>
                  <li ><Link  className='linkreact' id='link5' to='/contact' >Contact</Link></li>
                </ul>
              </nav>
              </header>

              </div>
              <div className='background_propos' >
                    <h1 className='propos_h1' >4 VILLAGES, 1 VOIX</h1>
              </div>
              <div className='div_histoir_propos' >
                <h3 className='h3_propos' >Notre Histoire</h3>
                <p className='p_propos' >
                Rebecq Vivant, c’est d’abord la rencontre de 8 citoyennes qui partagent leurs constats et
leurs réflexions autour de faits marquants concernant la commune et sa gestion. Elles
décident alors de créer un mouvement collectif citoyen, un mouvement actif, dynamique,
« vivant », qui se réunit régulièrement et se mobilise autour d’enjeux prioritaires pour le bien-être à Rebecq. <br></br><br></br>
L’objectif premier est de faire valoir le droit à l’information du citoyen dans certains dossiers,
comme ce fut le cas pour la vente du terrain Sportissimo ou lors du glissement des terres de
découverture de Heidelberg Materials (anciennement Sagrex).<br></br><br></br>
Elles se rapprochent alors du politique en siégeant dans des assemblées citoyennes ou des
comités, et interpellent directement le Conseil Communal, mais constatent qu’elles ne sont
pas entendues par les autorités locales.<br></br><br></br>
Alors rejointes par d’autres citoyens, le mouvement développe une nouvelle dynamique et
s’ouvre à une diversité de profils qui sont une réelle plus-value pour le groupe.
Ensemble, ils décident de franchir un cap : celui de lancer une liste électorale pour offrir une
alternative forte lors des prochaines élections communales.<br></br><br></br>
La liste Rebecq Vivant est motivée par l’envie d’impacter concrètement les dossiers
importants pour Rebecq, en faisant preuve de plus d’écoute envers les besoins des
habitants, mais aussi plus de respect des intérêts collectifs.

                </p>
              </div>
              <div className='galerie' id='galerie' >

              </div>
              <div className='line' ></div>
              <div className='combat_div' >
              
                <div className='combat_texte' >
                <h3 className='h3_propos combats_h3_propos' >Notre engagement</h3>
                <p className='p_propos combats_p_propos' >
                Nous nous engageons à gérer la commune sur une vision à long terme en informant les
citoyens des grands projets pour Rebecq et en facilitant la démocratie participative. Nous
étudions et déterminons les objectifs de notre programme de sorte qu’ils soient précis,
mesurables, atteignables, pertinents et définis dans le temps. Le fil
conducteur de notre stratégie est de faire mieux pour la qualité de vie des Rebecquois.
                </p>
                </div>
              </div>
              <div className='membres' >
                <h2 className='propos_membres_h2' >Nos Membres</h2>
                <div className='grid_membres' id='grid_membres' >

                </div>
              </div>
              <img className='bigimg' src='/imgnotfound.jpeg' id='bigimg' ></img>
              <div className='delbigimg' id='delbigimg' onClick={()=>{
                document.getElementById('bigimg').style.display = 'none'
                document.getElementById('delbigimg').style.display = 'none'
              }} ></div>
                 <footer>
                <div className='socials_footer' >
                <img  onClick={()=>{
                    window.open('https://www.instagram.com/rebecqvivant2024/', '_blank')
                  }} src='/footer/instagram.png' ></img>
                  <img onClick={()=>{
                     window.open(
                      "mailto: info@rebecqvivant.be")
                  }} src='/footer/email.png' ></img>
                  <img onClick={()=>{
                    window.open('https://www.facebook.com/rebecqvivant?locale=fr_FR', '_blank')
                  }} src='/footer/facebook.png' ></img>

                </div>
                <ul className='nav_footer' >
                <li  > <Link className='linkreact' id='link' to='/' > Accueil</Link></li>
                  <li  > <Link  className='linkreact' id='link1' to='/programme' >Programme</Link></li>
                  <li ><Link  className='linkreact' id='link2' to='/actualité' >Actualité</Link></li>
                  <li   ><Link  className='linkreact' id='link3' to='/agenda' >Agenda</Link></li>
                  <li  > <Link  className='linkreact' id='link4' to='/àpropos' >A propos</Link></li>
                  <li ><Link  className='linkreact' id='link5' to='/contact' >Contact</Link></li>
                </ul>


              </footer>
              <div className='pub_Neodia_Web' ><h6>Ces images sont soumises au droit d'auteur, veuillez nous contacter à <span onClick={()=>{
                window.open('mailto:info@rebecqvivant.be')
              }} >info@rebecqvivant.be</span>.</h6></div>
            <div className='pub_Neodia_Web' ><h6>Ce site a été créé par un jeune talent rebecquois.</h6></div>
        </div>
    )
    function createMembreCard(image, name, poste) {
      const container = document.getElementById('grid_membres');
      const filmCardElement = React.createElement(MembreCard, {image:image, name:name, poste:poste});

      const div = document.createElement('div');

      container.appendChild(div);
      ReactDOM.render(filmCardElement, div);
    }
    function createMembreConCard(image, name, poste) {
      const container = document.getElementById('grid_membres');
      const filmCardElement = React.createElement(MembreCardCon, {image:image, name:name, poste:poste});

      const div = document.createElement('div');

      container.appendChild(div);
      ReactDOM.render(filmCardElement, div);
    }
    function createMembreCPASCard(image, name, poste) {
      const container = document.getElementById('grid_membres');
      const filmCardElement = React.createElement(MembreCardCPAS, {image:image, name:name, poste:poste});

      const div = document.createElement('div');

      container.appendChild(div);
      ReactDOM.render(filmCardElement, div);
    }
    function createBigCardPhoto(data, number) {
      const container = document.getElementById('galerie');
      const filmCardElement = React.createElement(BigCardPhoto, {data:data, number:number});

      const div = document.createElement('div');

      container.appendChild(div);
      ReactDOM.render(filmCardElement, div);
    }
    function createThreeCardPhoto(img1, img2, img3) {
      const container = document.getElementById('galerie');
      const filmCardElement = React.createElement(ThreeCardPhoto, {img1:img1, img2:img2, img3: img3});

      const div = document.createElement('div');

      container.appendChild(div);
      ReactDOM.render(filmCardElement, div);
    }
    function createTwoCardPhoto(img1, img2) {
      const container = document.getElementById('galerie');
      const filmCardElement = React.createElement(TwoCardPhoto, {img1:img1, img2:img2});

      const div = document.createElement('div');

      container.appendChild(div);
      ReactDOM.render(filmCardElement, div);
    }
    function createOneCardPhoto(data) {
      const container = document.getElementById('galerie');
      const filmCardElement = React.createElement(OneCardPhoto, {data:data});

      const div = document.createElement('div');

      container.appendChild(div);
      ReactDOM.render(filmCardElement, div);
    }

    function BigCardPhoto({data, number}) {
      console.log(data[(number*4)+3]);
      console.log(((number*4)+3));
      console.log(number);
      const img1 = data[(number*4)+0].img
      const img2 = data[(number*4)+1].img
      const img3 = data[(number*4)+2].img
      const img4 = data[(number*4)+3].img

      return(
        <div className='wrap_group_photo' >
                  <div className='above_photo' >
                <div style={{backgroundImage:'url("'+img1+'")', backgroundSize:'cover'}}  className='photo1 photo' onClick={()=>{
                  document.getElementById('bigimg').style.display = 'block'
                  document.getElementById('bigimg').src = img1
                document.getElementById('delbigimg').style.display = 'block'
                }}  ></div>
                <div style={{backgroundImage:'url("'+img2+'")', backgroundSize:'cover'}} className='photo2 photo' onClick={()=>{
                  document.getElementById('bigimg').style.display = 'block'
                  document.getElementById('bigimg').src = img2
                document.getElementById('delbigimg').style.display = 'block'
                }} ></div>

                  </div>
                  <div className='under_photo' >
                <div style={{backgroundImage:'url("'+img3+'")', backgroundSize:'cover'}} className='photo3 photo' onClick={()=>{
                  document.getElementById('bigimg').style.display = 'block'
                  document.getElementById('bigimg').src = img3
                document.getElementById('delbigimg').style.display = 'block'
                }} ></div>
                <div style={{backgroundImage:'url("'+img4+'")', backgroundSize:'cover'}} className='photo4 photo' onClick={()=>{
                  document.getElementById('bigimg').style.display = 'block'
                  document.getElementById('bigimg').src = img4
                document.getElementById('delbigimg').style.display = 'block'
                }} ></div>

                  </div>

                </div>
      )
    }
    function ThreeCardPhoto({img1, img2, img3}) {
      return(
        <div className='wrap_group_photo' >
                  <div className='above_photo' >
                <div style={{backgroundImage:'url("'+img1+'")', backgroundSize:'cover'}} className='photo1 photo' onClick={()=>{
                  document.getElementById('bigimg').style.display = 'block'
                  document.getElementById('bigimg').src = img1
                document.getElementById('delbigimg').style.display = 'block'
                }} ></div>
                <div style={{backgroundImage:'url("'+img2+'")', backgroundSize:'cover'}} className='photo2 photo' onClick={()=>{
                  document.getElementById('bigimg').style.display = 'block'
                  document.getElementById('bigimg').src = img2
                document.getElementById('delbigimg').style.display = 'block'
                }} ></div>

                  </div>
                  <div className='under_photo' >
                <div style={{backgroundImage:'url("'+img3+'")', backgroundSize:'cover'}} className='photo3 photo' onClick={()=>{
                  document.getElementById('bigimg').style.display = 'block'
                  document.getElementById('bigimg').src = img3
                document.getElementById('delbigimg').style.display = 'block'
                }} ></div>


                  </div>

                </div>
      )
    }
    function TwoCardPhoto({img1, img2}) {
      return(
        <div className='wrap_group_photo' >
                  <div className='above_photo' >
                <div className='photo1 photo' style={{backgroundImage:'url("'+img1+'")', backgroundSize:'cover'}} onClick={()=>{
                  document.getElementById('bigimg').style.display = 'block'
                  document.getElementById('bigimg').src = img1
                document.getElementById('delbigimg').style.display = 'block'
                }} ></div>
                <div className='photo2 photo' style={{backgroundImage:'url("'+img2+'")', backgroundSize:'cover'}} onClick={()=>{
                  document.getElementById('bigimg').style.display = 'block'
                  document.getElementById('bigimg').src = img2
                document.getElementById('delbigimg').style.display = 'block'
                }}></div>

                  </div>


                </div>
      )
    }
    function OneCardPhoto({data}) {
      return(
        <div className='wrap_group_photo' >
                  <div className='above_photo' >
                <div className='photo1 photo' style={{backgroundImage:'url("'+data+'")', backgroundSize:'cover'}} onClick={()=>{
                  document.getElementById('bigimg').style.display = 'block'
                  document.getElementById('bigimg').src = data
                document.getElementById('delbigimg').style.display = 'block'
                }} ></div>


                  </div>


                </div>
      )
    }
    function MembreCard({image}) {
      return(
        <div className='wrap_membre' >
          
        <img className='photo_membre' src={image} ></img>

      </div>
      )

    }
    function MembreCardCon({image}) {
      return(
        <div className='wrap_membre' >
          <div className='ticket_cummunale'>Conseiller(e) communale</div>
        <img className='photo_membre' src={image} ></img>

      </div>
      )

    }
    function MembreCardCPAS({image}) {
      return(
        <div className='wrap_membre' >
          <div className='ticket_cummunale cpas'>Conseiller(e) CAS</div>
        <img className='photo_membre' src={image} ></img>

      </div>
      )

    }
}
export default Propos;