import './Actu.css'
import ReactDOM from 'react-dom'

import React, { useState, useEffect  } from 'react';
import { Link, useNavigate, Navigate } from 'react-router-dom'
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics, settings } from "firebase/analytics";
import { getDatabase, ref, child, get } from 'firebase/database';
import { doc } from 'firebase/firestore';

function Actu() {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.replace("#", ""); // Get article ID from hash
    if (hash) {
      openArticle(hash); // Function to display article
    }
  }, [location]); // Runs whenever l
  useEffect(() => {
    document.title = 'Rebecq Vivant | Actualité';
  }, []);
  window.scrollTo(0, 0)
  window.addEventListener('scroll',()=>{
    console.log(window.scrollY);
    if (window.scrollY>=4 && window.innerWidth >825) {
         document.getElementById('navbar').style.backgroundColor = 'white'
            document.getElementById('navbar').style.boxShadow = '1px 1px 30px black'
            document.getElementById('link').style.color = 'black'
            document.getElementById('link1').style.color = 'black'
            document.getElementById('link2').style.color = 'black'
            document.getElementById('link3').style.color = 'black'
            document.getElementById('link4').style.color = 'black'
            document.getElementById('link5').style.color = 'black'
    }
    else if (window.scrollY>=100 && window.innerWidth <=825) {
         document.getElementById('hamburger').style.backgroundColor = "black"
    }
    else if(window.scrollY<4){
      document.getElementById('hamburger').style.backgroundColor = "transparent"
        document.getElementById('navbar').style.backgroundColor = 'transparent'
            document.getElementById('navbar').style.boxShadow = ''
            document.getElementById('link').style.color = 'white'
            document.getElementById('link1').style.color = 'white'
            document.getElementById('link2').style.color = 'white'
            document.getElementById('link3').style.color = 'white'
            document.getElementById('link4').style.color = 'white'
            document.getElementById('link5').style.color = 'white'
    }
})
const dbRef = ref(getDatabase());
get(child(dbRef, 'articles')).then(async (snapshot) => {
  const objarticle = snapshot.val()
  const allarticles = Object.keys(objarticle)
  for (let i = allarticles.length; i > 0; i--) {
    const element = allarticles[i-1];
    get(child(dbRef, 'articles/'+element)).then(async (snapshotelement) => {
      const article = snapshotelement.val()
      const image = article.coverImage
      const title = article.title
      const date = article.date
      const previewtext = article.previewtext
      console.log(image)
     
        if (previewtext.length>200) {
          var goodprev =  previewtext.slice(0, 200 - previewtext.length) + '...'
          createArticle(title, date, image, goodprev, element, previewtext)
        }
        else{

          createArticle(title, date, image, previewtext, element, previewtext)
        }
      
      
    })
    
  }
})
    return(
        <div className='main_actu' >
            <div className='hamburger' id='hamburger' onClick={()=>{
               const hamb1 = document.getElementById('hamb1')
               const hamb2 = document.getElementById('hamb2')

        if (document.getElementById('header').style.display == 'block') {
             hamb1.style.transform = 'rotate(0deg)'
            hamb2.style.transform = 'rotate(0deg)'

            document.getElementById('actu_embed').style.position ='block'
            document.getElementById('hamb_hide').style.backgroundColor ='white'
            document.getElementById('hamb_del2').style.backgroundColor ='white'
            document.getElementById('hamb_del').style.backgroundColor ='white'
          document.getElementById('header').style.animationName = 'disapear'
          setTimeout(() => {
            document.getElementById('header').style.animationName = 'none'


            document.getElementById('header').style.display = 'none'
          }, 1000);
          }else{
            document.getElementById('actu_embed').style.position ='absolute'
             document.getElementById('hamb_del2').style.backgroundColor ='transparent'
            document.getElementById('hamb_del').style.backgroundColor ='transparent'
            document.getElementById('hamb_hide').style.backgroundColor ='transparent'
            hamb1.style.transform = 'rotate(45deg)'
            hamb2.style.transform = 'rotate(-45deg)'
      document.getElementById('header').style.display = 'block'
        }
      }}>
          <div style={{transition:'.3s all ease-in-out', backgroundColor:'white'}} id="hamb_del" ></div>
          <div id="hamb_hide" style={{marginLeft:'.1em' ,position:'relative', transition:'.3s all ease-in-out'}} >
            <div id="hamb1" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
            <div id="hamb2" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
          </div>
          <div style={{transition:'.3s all ease-in-out', backgroundColor:'white'}} id="hamb_del2" ></div>
      </div>
      <div className='fixed' >
              <header id='header'>
              <nav id='navbar' className='navbar'>
                <img className='logo' src='/newlogo.JPG' ></img>
                <ul className='nav-links'>
                <li  > <Link className='linkreact' id='link' to='/' > Accueil</Link></li>
                  <li  > <Link  className='linkreact' id='link1' to='/programme' >Programme</Link></li>
                  <li ><Link  className='linkreact' id='link2' to='/actualité' >Actualité</Link></li>
                  <li   ><Link  className='linkreact' id='link3' to='/agenda' >Agenda</Link></li>
                  <li  > <Link  className='linkreact' id='link4' to='/àpropos' >À propos</Link></li>
                  <li ><Link  className='linkreact' id='link5' to='/contact' >Contact</Link></li>
                </ul>
              </nav>
              </header>
              </div>
              <div className='degra2 actud' ></div>
              <h1 className='h1_actu' >Actualité</h1>
              <div className='actu_list' id='actu_list' >
                
              </div>
     
        <footer style={{display:'none'}}>
                <div className='socials_footer' >
                <img  onClick={()=>{
                    window.open('https://www.instagram.com/rebecqvivant2024/', '_blank')
                  }} src='/footer/instagram.png' ></img>
                  <img onClick={()=>{
                     window.open(
                      "mailto: info@rebecqvivant.be")
                  }} src='/footer/email.png' ></img>
                  <img onClick={()=>{
                    window.open('https://www.facebook.com/rebecqvivant?locale=fr_FR', '_blank')
                  }} src='/footer/facebook.png' ></img>
              
                </div>
                <ul className='nav_footer' >
                <li  > <Link className='linkreact' id='link' to='/' > Accueil</Link></li>
                  <li  > <Link  className='linkreact' id='link1' to='/programme' >Programme</Link></li>
                  <li ><Link  className='linkreact' id='link2' to='/actualité' >Actualité</Link></li>
                  <li   ><Link  className='linkreact' id='link3' to='/agenda' >Agenda</Link></li>
                  <li  > <Link  className='linkreact' id='link4' to='/àpropos' >A propos</Link></li>
                  <li ><Link  className='linkreact' id='link5' to='/contact' >Contact</Link></li>
                </ul>
             

              </footer>
              <div style={{display:'none'}} className='pub_Neodia_Web' ><h6>Ce site a été créé par un jeune talent rebecquois.</h6></div>
      <div className='wrap_article' id='wrap_article' >
        <img src='/fermer_black.png' className='closearticle' id='closearticle'  onClick={()=>{
          document.getElementById('wrap_article').style.display = 'none'
        }}></img>
        <div className='spawnelement' id='spawnelement' >

      
        </div>
        
      </div>
      <div className='linkc' id='linkc'>Lien Copié</div>
      </div>
    )
    function openArticle(name) {
      document.getElementById("wrap_article").style.display = "block";
      document.getElementById("spawnelement").innerHTML = "";
  
      const dbRef = ref(getDatabase());
      get(child(dbRef, "articles/" + name)).then((snapshot) => {
        const article = snapshot.val();
        if (article) {
          createHeaderImage(article.coverImage);
          createTitle(article.title);
          createParagraph(article.previewtext);
  
          // Load article blocks
          get(child(dbRef, "articles/" + name + "/blocks")).then((snapshotBlocks) => {
            const blocks = snapshotBlocks.val();
            if (blocks) {
              Object.keys(blocks).forEach((key) => {
                const block = blocks[key];
                if (block.type === "paragraph") createParagraph(block.content);
                else if (block.type === "subtitle") createSubtitle(block.content);
                else if (block.type === "image") createImage(block.content);
              });
            }
          });
        }
      });
    }
  
    function createArticle(title, date, img, textpreview, name, firsttext) {
      const container = document.getElementById('actu_list');
      const filmCardElement = React.createElement(Article, {title:title,date:date, img:img, textpreview:textpreview, name:name, firsttext:firsttext});
      const div = document.createElement('div');

      container.appendChild(div);
      ReactDOM.render(filmCardElement, div);
    }
    function Article({title, date, img, textpreview, name, firsttext}) {
      console.log('loaded:', img)
     
        return(
            <div id={name} className='article_actu' >
                    <div style={{background:'url("'+img+'")', backgroundSize:"cover", backgroundPosition:'center'}}  className='img_article_' ></div>
                    
                    <div className='text_preview_article' >
              <div className='czc' onClick={()=>{
document.getElementById('wrap_article').style.display = 'block'
document.getElementById('spawnelement').innerHTML = ''
createHeaderImage(img)
createTitle(title)
createParagraph(firsttext)
const dbRef = ref(getDatabase());
get(child(dbRef, 'articles/'+name+'/blocks')).then(async (snapshotpreview) => {
  const obj_all = snapshotpreview.val()
  const real_array = Object.keys(obj_all)
    for (let l = 0; l < real_array.length; l++) {
      const element = real_array[l];
      get(child(dbRef, 'articles/'+name+'/blocks/'+element)).then(async (snapshotelement) => {
        const objelements = snapshotelement.val()
        const array_elements = Object.keys(objelements)
        var type = objelements.type
        console.log(type == 'paragraph')
        if (type == 'paragraph') {
          console.log('created')
          createParagraph(objelements.content)
        }
        else if (type == 'subtitle') {
          createSubtitle(objelements.content)
        }
        else if (type == 'image') {
          createImage(objelements.content)
        }
      })
      
    }
}) 
              }}>

                        <h3 className='text_preview_article_h3' id='text_preview_article_h3'>{title}</h3>
                        <p className='text_preview_article_p'>{textpreview}</p>
              </div>
                        <button className='btnpartage'  onClick={()=>{
                          navigator.clipboard.writeText('https://rebecqvivant.be/actualit%C3%A9'+'#'+name);
                          document.getElementById('linkc').style.display = 'block'
                          setTimeout(() => {
                                  document.getElementById('linkc').style.display = 'none'
                          }, 2000);
                        }}> <img className='copy' src='/partager.png' ></img> </button>
                        <p className='text_preview_article_date' id='text_preview_article_date'>{date}</p>
                    </div>

                </div>
        )
    }
    function createParagraph(content) {
      const container = document.getElementById('spawnelement');
      const filmCardElement = React.createElement(Paragraph, {content:content});
      const div = document.createElement('div');

      container.appendChild(div);
      ReactDOM.render(filmCardElement, div);
    }
    function createTitle(content) {
      const container = document.getElementById('spawnelement');
      const filmCardElement = React.createElement(Title, {content:content});
      const div = document.createElement('div');

      container.appendChild(div);
      ReactDOM.render(filmCardElement, div);
    }
    function createSubtitle(content) {
      const container = document.getElementById('spawnelement');
      const filmCardElement = React.createElement(Subtitle, {content:content});
      const div = document.createElement('div');

      container.appendChild(div);
      ReactDOM.render(filmCardElement, div);
    }
    function createImage(content) {
      const container = document.getElementById('spawnelement');
      const filmCardElement = React.createElement(Image, {content:content});
      const div = document.createElement('div');

      container.appendChild(div);
      ReactDOM.render(filmCardElement, div);
    }
    function createHeaderImage(content) {
      const container = document.getElementById('spawnelement');
      const filmCardElement = React.createElement(HeaderImage, {content:content});
      const div = document.createElement('div');

      container.appendChild(div);
      ReactDOM.render(filmCardElement, div);
    }
    function Paragraph({content}) {
        return(
          <p style={{color:'black', marginBottom:'2em'}} className='paragraph_full_article' >{content}</p>

        )
    }
    function Title({content}) {
        return(
          <h3 style={{color:'black'}} className='title_full_article'  >{content}</h3>

        )
    }
    function Subtitle({content}) {
        return(
          <h6 style={{color:'black'}} className='subtitle_full_article'>{content}</h6>

        )
    }
    function Image({content}) {
        return(
          <div className='img_full_article' style={{backgroundImage:'url("'+content+'")'}} ></div>
        )
    }
    function HeaderImage({content}) {
        return(
          <img src={content} className='img_ful_article background_img_article' ></img>
        )
    }
    
}

export default Actu;